interface State {
    topTracks: [],
    topTracksLoading: boolean
}

const initState : State = {
    topTracks: [],
    topTracksLoading: false
};

const tracksReducer = (state = initState, action: any) => {
    switch(action.type) {
        case 'LOADING_FETCH_TOP_TRACKS':
            return {
                ...state,
                topTracksLoading: true,
            }
        case 'FETCH_TOP_TRACKS':
            return {
                ...state,
                topTracksLoading: false,
                topTracks: action.payload
            }
        default:
            return state;
    }
};

export default tracksReducer;