import React, { useState, useEffect } from 'react';
/** Icon */
import HeadsetIcon from '../../assets/images/headset_icon.svg';
/** Style */
import './style.css';

interface Props {
    name?: string,
    listener: string,
    title: string,
    url: string
}
 
const Card: React.FC<Props> = ({name, listener, title, url, ...props}) => {

    
    return (
        <div className='card'>
            <div className='card_header'>
                <h3>{title}</h3>
            </div>
            {
                name && (   
                    <h4 className='card_singer'>By: {name}</h4>
                )
            }
            <div className='card_listener_container'>
                <img src={HeadsetIcon}/>
                <span>{listener} people</span>
            </div>
            <div className='action_container'>
                <a href={url} target="_blank">LISTEN NOW</a>
            </div>
        </div>
    );
}
 
export default Card;