import React, { useState, useEffect } from 'react';
/** Store */
import { useDispatch, useSelector } from 'react-redux';
/** Actions */
import { getArtist, searchArtist } from  '../../store/Artist/action';
import { getTracks, searchTracks } from '../../store/Tracks/action';
/** Images */
import Banner from '../../assets/images/banner.jpg';
/** Component */
import Card from '../../component/Card';
/** Icon */
import CDIcon from '../../assets/images/cd_icon.svg';
import PersonIcon from '../../assets/images/person_icon.svg';
import SearchIcon from '../../assets/images/search_icon.svg';
import LoadingGif from '../../assets/images/loading.gif';
/** Style */
import './style.css';

interface Props {
    
}

interface ListMenu {
    title: string,
    isActive: boolean,
    icon: any
}
 
const Home: React.FC<Props> = () => {

    const dispatch = useDispatch();

    const { topTracks } = useSelector((state: any) => state.tracks);

    const { topTrackArtist } = useSelector((state: any) => state.artist);

    const [ listMenu, setListMenu ] = useState<ListMenu[]>(
        [
            {
                title: 'Top Tracks',
                isActive: true,
                icon: CDIcon
            },
            {
                title: 'Top Artists',
                isActive: false,
                icon: PersonIcon
            },
            {
                title: 'Search',
                isActive: false,
                icon: SearchIcon
            }
        ]
    );

    const [ selectInput, setSelectInput ] = useState<string>('Tracks');

    const [ searchInput, setSearchInput ] = useState<any>('');

    const [ isFetchingData, setIsFetchingData ] = useState<boolean>(false);

    const [ activeTabs, setActiveTabs ] = useState<string>('Top Tracks');
    
    useEffect(() => {
        const filtered = listMenu.filter((menu: ListMenu, menuIdx: number) => {
            return menu.isActive;
        })

        if(filtered[0].title === 'Top Tracks') {
            fetchTracks();
        } else if(filtered[0].title === 'Top Artists') {
            fetchArtist();
        }
    }, [listMenu]);

    const fetchArtist = async () => {
        try {

            if(topTrackArtist.length === 0) {
                setIsFetchingData(true);

                await dispatch(getArtist());
    
                setIsFetchingData(false);
            }

        } catch(error) {
            console.log(error);
        }
    };

    const fetchTracks = async () => {
        try {

            if(topTracks.length === 0) {
                setIsFetchingData(true);

                await dispatch(getTracks());
    
                setIsFetchingData(false);
            }

        } catch(error) {
            console.log();
        }
    };


    const navigateListMenu = (index : number) => {
        const newListMenu = listMenu.map((menu: ListMenu, menuIdx: number) => {
            menu.isActive = false;

            if(index === menuIdx) {
                menu.isActive = true;
                setActiveTabs(menu.title);
            }

            return menu;
        });

        setListMenu(newListMenu);
    };

    const renderTopTracks = (data: {[key: string] : any}, type: string) => {
        console.log(data);
            return (
                <div className='item_result'>
                    <Card
                        title={data.name}
                        name={data.artist.name ? data.artist.name : data.artist}
                        listener={data.listeners}
                        url={data.url}
                    />
                </div>
            )        
    };

    const renderTopArtist = (data: {[key: string]: any}, type: string) => {
            return(
                <div className='item_result'>
                    <Card
                        title={data.name}
                        listener={data.listeners}
                        url={data.url}
                    />
                </div>
            )
        
    };

    const submitSearch = async (e : any) => {
        try {
            e.preventDefault();

            setIsFetchingData(true);
        
            if(selectInput === 'Tracks') {
                await dispatch(searchTracks({search: searchInput}));
            } else {
                await dispatch(searchArtist({search: searchInput}));
            }

            setIsFetchingData(false);

        } catch(error) {
            console.log(error);
        }
    }

    const handleSearchInput = (e: any) => {
        setSearchInput(e.target.value);
    }


    return (
        <div className='home'>
            <div className='banner_container'>
                <img src={Banner} className="banner"/>
                <div className='banner_overlay'/>
                <h1>Welcome !</h1>
                <h3>Music is not what we do <br></br> but who we are.</h3>
            </div>
            <div className='menu_container' style={{height: listMenu[2].isActive ? '400px' : '300px'}}>
                <h2>Explore your favorites music</h2>
                <div className="list_menu">
                    {
                        listMenu.map((menu: ListMenu, menuIdx: number) => (
                            <div className="list_item" key={menuIdx} onClick={() => navigateListMenu(menuIdx)}>
                                <div className='list_icon_container' style={{backgroundColor: menu.isActive ? '#B41EB2' : '#38A6F3'}}>
                                    <img src={menu.icon}/>
                                </div>
                                <h3>{menu.title}</h3>
                            </div>
                        ))
                    }
                </div>
                {
                    listMenu[2].isActive 
                    ?
                        <form className='form_search' onSubmit={(e) => submitSearch(e)}>
                            <input type="text" placeholder='Type something here' value={searchInput} onChange={(e) => handleSearchInput(e)}/>
                            <select value={selectInput} onChange={(e) => setSelectInput(e.target.value)}>
                                <option value="Tracks" selected>Tracks</option>
                                <option value="Artist" selected>Artist</option>
                            </select>
                        </form>
                    :
                    <></>
                }
            </div>
            <div className="result_container">
                <h2>Results</h2>
                {
                    isFetchingData && (
                        <>
                            <img src={LoadingGif} className="loading"/>
                            <h4 className='loading_text'>Please wait guys !</h4>
                        </>
                    )
                }
                <div className='list_result'>
                    {
                        activeTabs === 'Top Tracks' 
                        ?
                        topTracks.map((track: {[key: string]: any}, trackIdx: number) => (
                            renderTopTracks(track, 'static')
                        ))
                        :
                        activeTabs === 'Top Artists'
                        ?
                        topTrackArtist.map((track: {[key: string]: any}, trackIdx: number) => (
                            renderTopArtist(track, 'static')
                        ))
                        :
                        activeTabs === 'Search'
                        ?
                            searchInput.length > 0
                            ?
                                selectInput === 'Tracks'
                                ?
                                topTracks.map((track: {[key: string]: any}, trackIdx: number) => (
                                    renderTopTracks(track, 'dynamic')
                                ))
                                :
                                topTrackArtist.map((track: {[key: string]: any}, trackIdx: number) => (
                                    renderTopArtist(track, 'dynamic')
                                ))
                            :
                            <></>
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    );
}
 
export default Home;