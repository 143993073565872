interface State {
    topTrackArtist: [],
    topTrackArtistLoading: boolean
}

const initState : State = {
    topTrackArtist: [],
    topTrackArtistLoading: false
};

const artistReducer = (state = initState, action: any) => {
    switch(action.type) {
        case 'LOADING_FETCH_TOP_TRACK_ARTIS':
            return {
                ...state,
                topTrackArtistLoading: true,
            }
        case 'FETCH_TOP_TRACK_ARTIST':
            return {
                ...state,
                topTrackArtistLoading: false,
                topTrackArtist: action.payload
            }
        default:
            return state;
    }
};

export default artistReducer;