import { combineReducers } from 'redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
/** Reducers */
import artistReducer from './Artist/reducer';
import tracksReducer from './Tracks/reducer';

const reducers = combineReducers({
    artist: artistReducer,
    tracks: tracksReducer
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;