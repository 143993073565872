import axios from 'axios';
import { baseURL, apiKey } from '../../const/configUrl';

export const getTracks = (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await axios.get(`${baseURL}method=chart.gettoptracks&api_key=${apiKey}&format=json&page=1&limit=10`);

            resolve(dispatch({
                type: 'FETCH_TOP_TRACKS',
                payload: [...response.data.tracks.track]
            }));

        } catch {
            console.log('error');
        }
    });
};

export const searchTracks = (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await axios.get(`${baseURL}method=track.search&track=${params.search}&api_key=${apiKey}&format=json&page=1&limit=10`);

            resolve(dispatch({
                type: 'FETCH_TOP_TRACKS',
                payload: [...response.data.results.trackmatches.track]
            }));

        } catch(error) {
            console.log('error');
        }
    })
}