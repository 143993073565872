import axios from 'axios';
import { baseURL, apiKey } from '../../const/configUrl';

export const getArtist = (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async(resolve, reject) => {
        try {


            const response = await axios.get(`${baseURL}method=chart.gettopartists&api_key=${apiKey}&format=json&page=1&limit=10`);


            resolve(dispatch({
                type: 'FETCH_TOP_TRACK_ARTIST',
                payload: [...response.data.artists.artist]
            }));
            
        } catch {
            console.log('error');
        }
    });
};

export const searchArtist = (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await axios.get(`${baseURL}method=artist.search&artist=${params.search}&api_key=${apiKey}&format=json`);
            resolve(dispatch({
                type: 'FETCH_TOP_TRACK_ARTIST',
                payload: [...response.data.results.artistmatches.artist]
            }));
        } catch(error) {
            console.log('error');
        }
    })
}