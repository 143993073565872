import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
/** Screen */
import Home from './screen/Home';
/** Action */
import { getArtist } from './store/Artist/action';
/** Third Party */
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>}/>
      </Routes>
    </div>
  );
}

export default App;
